<template>
  <BasicLayout :initVars="initVars"></BasicLayout>
</template>
<script>
/**
 * [廠商管理]
 */
import BasicLayout from '@/layouts/basic';

export default {
  name: 'Vendor',
  components: { BasicLayout },
  data () {
    return {
      initVars: {
        title: this.$route.meta.menuLabel
      }
    };
  },
  created () {
    // console.log('[Vendor/index][created] ...');
  },
  updated () {
    // console.log('[Vendor/index][updated] ...');
  }
};
</script>
